import React, { FC, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import IconTemplate, { Icons } from '@components/Icon';
import { ModalTypes } from '@components/Modal/Modal';
import useTranslations from '@hooks/useTranslation';
import ButtonTemplate from '@ui/Button/Button';
import { handleDownloadClick } from '@utils/material.utils';
import { publicAssets } from '@utils/url.utils';
import { EMAIL_REGEX, ROUTES } from '../../constants';
import { openModalType } from '../../store/ui';
import ContentTemplate from '../../ui/Content/Content';
import {
    AnimeImg,
    AnimeSection,
    AnimeSections,
    Background,
    Bottom,
    CatalogDescription,
    CatalogImage,
    CatalogSection,
    CopyrightLabel,
    CopyrightLink,
    CopyrightSection,
    Navigation,
    NavigationItem,
    NavigationLink,
    NavigationSection,
    NavigationTitle,
    NewsletterDescription,
    NewsletterSection,
    NewsletterTitle,
    SocialSection,
} from './Footer.elements';

export interface FooterProps {}

const COMPANY_NAVIGATION_ITEM_KEYS = [
    { title: 'footer.company.about', path: ROUTES.ABOUT_US },
    { title: 'footer.company.team', path: `${ROUTES.CONTACT}#nas-tym` },
    { title: 'footer.company.reference', path: `${ROUTES.ABOUT_US}#reference` },
    { title: 'footer.company.partners', path: `${ROUTES.ABOUT_US}#partneri` },
    { title: 'footer.company.contact', path: ROUTES.CONTACT },
];
const LEARNING_NAVIGATION_ITEM_KEYS = [
    { title: 'footer.learning.trainings', path: ROUTES.TRAININGS },
    { title: 'footer.learning.customTrainings', path: ROUTES.COMPANY_COURSES },
    { title: 'footer.learning.rent', path: ROUTES.CLASSROOM_RENT },
    { title: 'footer.learning.places', path: ROUTES.CONTACT },
    { title: 'footer.learning.blog', path: ROUTES.BLOG },
];

const FooterTemplate: FC<FooterProps> = ({}) => {
    const { t } = useTranslations();

    const router = useRouter();

    const dispatch = useDispatch();

    return (
        <Background id="footer">
            <AnimeSections>
                <AnimeSection>
                    <AnimeImg src={publicAssets('/eye.png')} />
                    {t('footer.anime.section1.label')}
                </AnimeSection>
                <AnimeSection>
                    <AnimeImg src={publicAssets('/ear.png')} />
                    {t('footer.anime.section2.label')}
                </AnimeSection>
                <AnimeSection>
                    <AnimeImg src={publicAssets('/hand.png')} />
                    {t('footer.anime.section3.label')}
                </AnimeSection>
                <AnimeSection>
                    <AnimeImg src={publicAssets('/mouth.png')} />
                    {t('footer.anime.section4.label')}
                </AnimeSection>
            </AnimeSections>
            <ContentTemplate>
                <Navigation>
                    <NavigationSection>
                        <NavigationTitle>{t('footer.company.title')}</NavigationTitle>
                        {COMPANY_NAVIGATION_ITEM_KEYS.map((item, key) => (
                            <NavigationItem key={key}>
                                <NavigationLink withoutMargin href={item.path}>
                                    {t(item.title)}
                                </NavigationLink>
                            </NavigationItem>
                        ))}
                    </NavigationSection>
                    <NavigationSection>
                        <NavigationTitle>{t('footer.learning.title')}</NavigationTitle>
                        {LEARNING_NAVIGATION_ITEM_KEYS.map((item, key) => (
                            <NavigationItem key={key}>
                                <NavigationLink withoutMargin href={item.path}>
                                    {t(item.title)}
                                </NavigationLink>
                            </NavigationItem>
                        ))}
                    </NavigationSection>
                    <NavigationSection>
                        <NavigationTitle>{t('footer.catalog.title')}</NavigationTitle>
                        <NavigationItem>
                            <CatalogSection>
                                <CatalogImage src={publicAssets('/catalog.png')} />
                                <CatalogDescription>{t('footer.catalog.description')}</CatalogDescription>
                            </CatalogSection>
                        </NavigationItem>
                        <NavigationItem>
                            <ButtonTemplate
                                onClick={() => handleDownloadClick('catalog', 'catalog.pdf', true)}
                                blackType
                                text={t('footer.catalog.button')}
                            />
                        </NavigationItem>
                    </NavigationSection>
                    <NavigationSection>
                        <NewsletterSection>
                            <NewsletterTitle>{t('footer.newsletter.title')}</NewsletterTitle>
                            <NewsletterDescription
                                dangerouslySetInnerHTML={{ __html: t('footer.newsletter.description') }}
                            />
                            <ButtonTemplate
                                blackType
                                text={t('footer.newsletter.button')}
                                onClick={() =>
                                    dispatch(
                                        openModalType({
                                            type: ModalTypes.NEWSLETTER,
                                        })
                                    )
                                }
                            />
                        </NewsletterSection>
                    </NavigationSection>
                </Navigation>
                <Bottom>
                    <CopyrightSection>
                        <CopyrightLabel>Copyright 2002 – 2024 © Studio W s.r.o.</CopyrightLabel>
                        <CopyrightLabel
                            onClick={() =>
                                router.push({
                                    pathname: router.pathname,
                                    query: {
                                        ...router.query,
                                        updateCookies: true,
                                    },
                                })
                            }
                        >
                            {t('footer.cookies')}
                        </CopyrightLabel>
                        <CopyrightLink href={ROUTES.CONDITIONS}>{t('footer.conditions')}</CopyrightLink>
                        <CopyrightLabel
                            onClick={() =>
                                router.push({
                                    pathname: ROUTES.GDPR,
                                })
                            }
                        >
                            {t('footer.privacy')}
                        </CopyrightLabel>
                    </CopyrightSection>
                    <SocialSection>
                        <NavigationLink href="https://www.facebook.com/FiremniVzdelavaniStudioW" target="_black">
                            <IconTemplate name={Icons.Facebook} width={35} height={35} />
                        </NavigationLink>
                        <NavigationLink href="https://www.linkedin.com/company/studio-w" target="_black">
                            <IconTemplate name={Icons.Linkedin} width={35} height={35} />
                        </NavigationLink>
                    </SocialSection>
                </Bottom>
            </ContentTemplate>
        </Background>
    );
};

export default FooterTemplate;
